// Export the base function-object
export default function (str) {

    let valid;
    reset();

    function isValidEmailAddress(pattern) {
        const regExp = new RegExp(pattern);
        return this.matches(regExp).isValid();
    }
    this.isValidEmailAddress = isValidEmailAddress;

    function isNotEmpty() {
        if (valid) {
            valid = str.length > 0;
        }
        return this;
    }
    this.isNotEmpty = isNotEmpty;

    function matches(regExp) {
        if (valid && regExp instanceof RegExp) {
            valid = regExp.test(str);
        }
        return this;
    }
    this.matches = matches;

    function isValid() {
        return valid;
    }
    this.isValid = isValid;

    function reset(newStr) {
        if (newStr) {
            str = newStr;
        }
        valid = (typeof str === "string" || str instanceof String);
    }
    this.reset = reset;
}
