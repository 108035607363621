import StringValidator from '@core/scripts/helper/string-validator';
import JSONParser from '@core/scripts/helper/json-parser';
import History from '@core/scripts/components/history';
import Breakpoint from '@core/scripts/helper/breakpoint.js';
import MessageBroker from '@bonprix/pattern-library/components/message-broker';
import Messages from '@core/scripts/components/messages';
import Topics from '@core/scripts/components/topics';
import ParamEncoder from '@core/scripts/helper/param-encoder';
import fetchWithCSRF from '@core/scripts/helper/fetch-with-csrf';
import NitroEventHelper from '@core/scripts/helper/nitro-event-helper';

const SEARCH_HISTORY_LIMIT = 8;
const SEARCH_NITRO_EVENT_TYPE = 'SEARCH';
const SEARCH_ORIGIN_SEARCH = 'textfield';
const SEARCH_ORIGIN_SUGGESTION = 'suggest';
const SEARCH_ORIGIN_HISTORY = 'lastsearch';
const UNEXPECTED_CHARACTERS_PATTERNS = /([^\p{L}\d ()/,.-]+)/gu;
const URL_PATTERN = /(((http|www)[^ ]*( |$))|[\\p{IsAlphabetic}\\d-]+\\.[\\p{IsAlphabetic}\\d-]{2,})/g;
const EMAIL_PATTERN = /(( |^)[^ ]*@[^ ]*)/g;
const PHONE_NUMBER_PATTERN = /([+0]?[\d /-]{8,}\d)/g;

const moduleScope = document.querySelector('[jsh-module="header/search"]');
const config = JSON.parse(moduleScope.getAttribute('jsh-config'));

const searchCloserNode = moduleScope.querySelector('[jsh-search__closer]');
const searchTriggerNode = moduleScope.querySelector('[jsh-search__trigger]');

const searchForm = moduleScope;
const searches = {};
const headerNode = searchForm.closest('[jsh-header]');
const searchWrapperNode = searchForm.querySelector('[jsh-search__wrapper]');
const searchSuggestionsWrapperNode = searchForm.querySelector('[jsh-search__suggestions]');
const searchHistoryWrapperNode = searchForm.querySelector('[jsh-search__history]');
const searchInputWrapper = searchForm.querySelector('[jsh-search__input-wrapper]');
searchInputWrapper.classList.add('is-initialized');
const searchInput = searchForm.querySelector('[jsh-search__input]');
const searchOriginNode = searchForm.querySelector('[jsh-search__origin]');
const placeholder = searchInput.placeholder;
const errorPlaceholder = searchInput.getAttribute('data-error-placeholder');
const shortErrorPlaceholder = searchInput.getAttribute('data-error-placeholder-short');
const hamburgerMenuCheckboxNode = document.querySelector('[jsh-hamburger-menu__checkbox]');
const searchCellNode = headerNode ? headerNode.querySelector('[jsh-search__cell]') : null;
let inputTimeout;

openSearch = openSearch;
setInputActive = setInputActive;
hideSearchSuggestionsWrapper = hideSearchSuggestionsWrapper;
closeSearch = closeSearch;
hideSearch = hideSearch;
hideSearchWhenNavigationIsOpened = hideSearchWhenNavigationIsOpened;
resetInputField = resetInputField;
validateInput = validateInput;
submitSearch = submitSearch;
getSearchSuggestions = getSearchSuggestions;
showSearchSuggestions = showSearchSuggestions;
updateSearchHistory = updateSearchHistory;
renderSearchHistory = renderSearchHistory;
isTargetOutsideSearch = isTargetOutsideSearch;
clearSearchHistory = clearSearchHistory;
showSearchHistory = showSearchHistory;
highlightKeyword = highlightKeyword;
bindSuggestion = bindSuggestion;
hideSearchSuggestions = hideSearchSuggestions;
hideSearchHistory = hideSearchHistory;
onInput = onInput;
setInputs = setInputs;

if (searchCellNode) {
    searchTriggerNode.addEventListener('click', openSearch);
    searchCloserNode.addEventListener('click', () => {
        History.remove('search');
        closeSearch();
    });
}
searchForm.addEventListener('submit', submitSearch);
searchInput.addEventListener('input', onInput);
searchInput.addEventListener('focusin', setInputActive);
searchInput.addEventListener('focusout', resetInputField);
window.addEventListener('click', hideSearchSuggestionsWrapper);
window.addEventListener('focusin', hideSearchSuggestionsWrapper);
MessageBroker.subscribe(Messages.NAVIGATION_ACCORDION.OPENED, () => {
    History.remove('search');
    hideSearchWhenNavigationIsOpened();
});
MessageBroker.subscribe(`${Topics.HISTORY}.search`, closeSearch);
renderSearchHistory();
setSearchInputToTheLatestValue();

function openSearch() {
    History.push('search');
    searchInput.focus();
    setInputActive();
    searchCellNode.classList.add('is-opened');
    headerNode.classList.add('is-search-active');
}

function setInputActive() {
    searchWrapperNode.classList.add('is-active');
    showSearchHistory();
}

function hideSearchSuggestionsWrapper(event) {
    const targetNode = event.target;
    if (isTargetOutsideSearch(targetNode)) {
        hideSearchSuggestions();
        hideSearchHistory();
    }
}

function isTargetOutsideSearch(targetNode) {
    return (
        !targetNode.closest('[jsh-search__history]') &&
        !targetNode.closest('[jsh-search__suggestions]') &&
        !targetNode.closest('[jsh-search__wrapper]')
    );
}

function closeSearch() {
    resetInputField();
    searchInput.value = '';
    searchInput.blur();
    hideSearch();
    hideSearchSuggestions();
}

function hideSearch() {
    // Android keyboard needs time to de-render itself - so, yield the right-of-way a bit.
    setTimeout(() => {
        searchCellNode.classList.remove('is-opened');
    }, 300);
    searchWrapperNode.classList.remove('is-active');
    headerNode.classList.remove('is-search-active');
    hideSearchSuggestions();
    hideSearchHistory();
}

function hideSearchWhenNavigationIsOpened() {
    if (!hamburgerMenuCheckboxNode.checked) {
        closeSearch();
    }
}

function resetInputField() {
    searchInput.placeholder = placeholder;
    searchWrapperNode.classList.remove('is-error', 'is-active');
}

function hideSearchSuggestions() {
    searchSuggestionsWrapperNode.classList.add('is-hidden');
}

function hideSearchHistory() {
    searchHistoryWrapperNode.classList.add('is-hidden');
}

function setSearchInputToTheLatestValue() {
    const searchParams = new URLSearchParams(window.location.search);
    const query = searchParams.get('qu');
    const queryWithFilter = searchParams.get('originQuery');
    if (queryWithFilter) {
        searchInput.value = queryWithFilter.replaceAll('+', ' ');
    } else if (query) {
        searchInput.value = cleanSearchValue(query);
    }
}

function onInput() {
    clearTimeout(inputTimeout);
    // don't send a search request for every character input
    inputTimeout = setTimeout(() => validateInput(), 300);
}

function validateInput() {
    if (new StringValidator(searchInput.value).isNotEmpty().isValid()) {
        getSearchSuggestions();
        searchInput.placeholder = placeholder;
    } else {
        hideSearchSuggestions();
        showSearchHistory();
    }
}

function submitSearch(event) {
    event.preventDefault();
    const searchValue = cleanSearchValue(searchInput.value);
    if (new StringValidator(searchValue).isNotEmpty().isValid()) {
        updateSearchHistory(searchValue);
        searchForm.removeEventListener('submit', submitSearch);
        NitroEventHelper.trigger(SEARCH_NITRO_EVENT_TYPE, {query: searchValue});
        searchForm.submit();
    } else {
        hideSearchSuggestions();
        searchWrapperNode.classList.add('is-error');
        if (Breakpoint.keyIsIn(['small', 'medium'])) {
            searchInput.placeholder = shortErrorPlaceholder;
        } else {
            searchInput.placeholder = errorPlaceholder;
        }
    }
}

function updateSearchHistory(searchValue) {
    const searchHistory = JSONParser.tryParseJSON(localStorage.getItem('searchHistory')) || [];
    const searchValueIndex = searchHistory.indexOf(searchValue);
    const searchValueIsAlreadyInHistory = searchValueIndex > -1;
    if (searchValueIsAlreadyInHistory) {
        searchHistory.splice(searchValueIndex, 1);
    }
    searchHistory.push(searchValue);
    localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
}

function cleanSearchValue(searchValue) {
    return searchValue
        .replace(URL_PATTERN, '')
        .replace(EMAIL_PATTERN, '')
        .replace(PHONE_NUMBER_PATTERN, '')
        .replace(UNEXPECTED_CHARACTERS_PATTERNS, '')
        .trim();
}

function renderSearchHistory() {
    const searchHistory = JSONParser.tryParseJSON(localStorage.getItem('searchHistory'));
    if (searchHistory && config.searchHistoryEnabled) {
        searchHistory.reverse().forEach((value, index) => {
            if (index < SEARCH_HISTORY_LIMIT) {
                const cleanedValue = cleanSearchValue(value);
                const searchHistoryEntryElement = document.createElement('div');
                const searchHistoryEntryInnerElement = document.createElement('div');
                searchHistoryEntryInnerElement.innerText = cleanedValue;
                searchHistoryEntryInnerElement.setAttribute('jsh-search__suggestion', '');
                searchHistoryEntryInnerElement.className = 'search__suggestion';
                searchHistoryEntryElement.className = 'search__suggestion-item -clickable';
                searchHistoryEntryElement.setAttribute('jsh-search__suggestion-item', '');
                searchHistoryEntryElement.append(searchHistoryEntryInnerElement);

                searchHistoryWrapperNode.insertAdjacentElement('beforeend', searchHistoryEntryElement);
            }
        });

        const searchHistoryLinkNodes = searchHistoryWrapperNode.querySelectorAll('[jsh-search__suggestion]');
        const searchHistoryDeleteNode = searchHistoryWrapperNode.querySelector('[jsh-search__history-delete]');
        searchHistoryLinkNodes.forEach((node) => {
            node.addEventListener('click', completeInputAndSubmitSearch.bind(this, SEARCH_ORIGIN_HISTORY));
        });
        searchHistoryDeleteNode.addEventListener('click', clearSearchHistory);
    }
}

function showSearchHistory() {
    if (
        searchHistoryWrapperNode.querySelectorAll('[jsh-search__suggestion-item]').length > 0 &&
        searchSuggestionsWrapperNode.classList.contains('is-hidden') &&
        config.searchHistoryEnabled
    ) {
        searchHistoryWrapperNode.classList.remove('is-hidden');
    }
}

function clearSearchHistory() {
    localStorage.removeItem('searchHistory');
    hideSearchHistory();
    searchHistoryWrapperNode.querySelectorAll('[jsh-search__suggestion-item]').forEach((node) => {
        if (!node.getAttribute('jsh-search__suggestion-headline')) {
            node.remove();
        }
    });
    searchInput.focus();
}

function getSearchSuggestions() {
    if (searchWrapperNode.classList.contains('is-error')) {
        searchWrapperNode.classList.remove('is-error');
    }

    const searchTerm = searchInput.value;
    if (searches[searchTerm]) {
        return showSearchSuggestions(searches[searchTerm]);
    }

    const queryString = ParamEncoder.encode({qu: searchTerm});
    return fetchWithCSRF(`${config.url}?${queryString}`, {
        method: 'GET',
    })
        .then((response) => {
            return response.text();
        })
        .then((data) => {
            searches[searchTerm] = data;
            showSearchSuggestions(data);
        });
}

function showSearchSuggestions(data) {
    hideSearchSuggestions();
    hideSearchHistory();
    searchSuggestionsWrapperNode.innerHTML = data;
    MessageBroker.publish(Messages.DOM.CHANGED, searchSuggestionsWrapperNode);
    const searchSuggestionNodes = searchSuggestionsWrapperNode.querySelectorAll('[jsh-search__suggestion]');
    searchSuggestionNodes.forEach((node) => {
        highlightKeyword(node);
        bindSuggestion(node);
    });
    if (searchSuggestionNodes.length > 0) {
        searchSuggestionsWrapperNode.classList.remove('is-hidden');
    }
}

function highlightKeyword(searchSuggestionNode) {
    const searchTerm = new RegExp(`(${searchInput.value})`, 'gi');
    searchSuggestionNode.innerHTML = searchSuggestionNode.textContent
        .trim()
        .replace(searchTerm, `<span class='search__suggestion-highlight'>$1</span>`);
}

function bindSuggestion(searchSuggestionNode) {
    const searchCompleterNode = searchSuggestionNode.nextElementSibling;
    searchSuggestionNode.addEventListener('click', completeInputAndSubmitSearch.bind(this, SEARCH_ORIGIN_SUGGESTION));
    searchCompleterNode.addEventListener('click', completeSearchInput.bind(this, SEARCH_ORIGIN_SEARCH));
}

function completeSearchInput(searchOrigin, e) {
    setInputs(e, searchOrigin);
    searchInput.focus();
}

function completeInputAndSubmitSearch(searchOrigin, e) {
    setInputs(e, searchOrigin);
    NitroEventHelper.trigger(SEARCH_NITRO_EVENT_TYPE, {query: searchInput.value});
    updateSearchHistory(searchInput.value);
    searchForm.submit();
}

function setInputs(e, searchOrigin) {
    const searchSuggestionItemNode = e.target.closest('[jsh-search__suggestion-item]');
    const searchSuggestionNode = searchSuggestionItemNode.querySelector('[jsh-search__suggestion]');

    searchWrapperNode.classList.remove('is-error');
    searchInput.value = searchSuggestionNode?.textContent;
    searchOriginNode.value = searchOrigin;
}
